import React from 'react';
import {Link} from 'react-router-dom';
import styling from './Pravilnici.module.css';

const Pravilnici = () => {

  return (
    <div className={styling.pravilnici}>
      <h3>Статут школе и правилници</h3>
      <div className={[styling.container, styling.containetBetween, styling.wrap].join(" ")}>
        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/180228Statut.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Статут школе
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikoradu.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о раду
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikobezbednostiucenika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о безбедности ученика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikoodgovornostizaposlenih.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о одговорности запослених
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/pravilaOdevanja.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правила одевања у школи
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikovanrednomskolovanju.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о ванредном школовању
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikonagradivanjuucenika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о похваљивању и награђивању ученика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikoispitima.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о полагању испита
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilnikoodgovornostiucenika.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о одговорности ученика
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Pravilaponasanja.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилa понашања
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Sistematizacija.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о организацији и систематизацији радних места
              </div>
            </Link>
        </div>
        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/INFORMATORGIMNAZIJA.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Информатор о раду
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/godisnjiPlan.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Годишњи план рада
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/razvojniPlan.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Развојни план
              </div>
            </Link>
        </div>
        
        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/skolskiProgram20212025.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Школски програм
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018Poslovnikoradusolskogodbora.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Пословник о раду школског одбора
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/2018PoslovnikSavetaroditelja.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Пословник о раду савета родитеља
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/Poslovnikucenickogparlamenta.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Пословник о раду ученичког парламента
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/dodatnaNastava2023-2024.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Додатна настава
              </div>
            </Link>
        </div>


        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/dopunskaNastava2023-2024.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Допунска настава
              </div>
            </Link>
        </div>

        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/pravilnikIzostanci.docx" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Правилник о критеријумима за правдање часова
              </div>
            </Link>
        </div>


        <div className={styling.pdfFile}>
            <Link to="/files/pravilnici/postupanjeRoditelja.pdf" target="_blank" rel="noopener noreferrer">
              <div className={styling.pdfIcon}>
                <img src="/pdfIcon.jpg" alt="pdf.icon" />
              </div>
              <div className={styling.pdfText}>
                Процедура поступања родитеља у случају када су незадовољни оценама или радом професора
              </div>
            </Link>
        </div>


      </div>
    </div>
  );

}

export default Pravilnici;

/*

*/
